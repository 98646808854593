<template>
  <div>
    <v-checkbox v-model="isVisible" label="Помощь" />

    <v-alert v-if="isVisible" dense type="info">
      Структура данных:
      <ul>
        <li>id - идентификатор уведомления</li>
        <li>name - имя сценария. Пример: "message" - отправка сообщения</li>
        <li>[прочие поля] - набор полей для конкретного сценария. Пример: "title", "body" для name=message</li>
      </ul>

      <br />

      Особенности:
      <ul>
        <li>Все значения строковые</li>
        <li>Отправка сообщения с одинаковым ID обновляет пуш на устройстве</li>
        <li>Если не указать ID в форме, то будет подставлен случайный</li>
      </ul>
    </v-alert>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false
    };
  }
};
</script>
