<template>
  <div>
    <h2 class="mb-4">Push-уведомления</h2>

    <v-sheet elevation="3">
      <v-tabs background-color="deep-purple secondary" center-active dark hide-slider>
        <v-tab>Текстовое сообщение</v-tab>
        <v-tab>Кастомное сообщение</v-tab>
        <v-tab>Локальная история</v-tab>

        <v-tab-item class="pa-4">
          <notification-message-form :loading="loading" @submit="sendNotification" />
        </v-tab-item>

        <v-tab-item class="pa-4">
          <notification-custom-form-help />
          <notification-custom-form :loading="loading" @submit="sendNotification" />
        </v-tab-item>

        <v-tab-item class="pa-4">
          <notification-local-history :history="history" />
        </v-tab-item>
      </v-tabs>
    </v-sheet>
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import NotificationMessageForm from "~/components/mobile/send-push/message-form";
import NotificationCustomForm from "~/components/mobile/send-push/custom-form";
import NotificationCustomFormHelp from "~/components/mobile/send-push/custom-form/help";
import NotificationLocalHistory from "~/components/mobile/send-push/history";

export default {
  data() {
    return {
      loading: false,
      history: []
    };
  },
  methods: {
    async sendNotification(push) {
      try {
        await this.$axios.$post("mobile-app/notifications", push);

        this.history.push(push);
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Пуш не отправлен: " + html);
      }
    }
  },
  components: {
    NotificationMessageForm,
    NotificationCustomForm,
    NotificationCustomFormHelp,
    NotificationLocalHistory
  }
};
</script>
