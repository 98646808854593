<template>
  <div>
    <template v-if="history.length > 0">
      <div v-for="(notification, ind) of history" :key="ind" class="mb-2">
        <code-block :value="notification" />
      </div>
    </template>
    <div v-else>Пусто</div>
  </div>
</template>

<script>
export default {
  props: {
    history: {
      type: Array,
      required: true
    }
  }
};
</script>
