<template>
  <v-form autocomplete="off" @submit.prevent="handleSubmit">
    <v-text-field v-model="token" label="Токен устройства" />
    <v-text-field v-model="title" label="Заголовок" />
    <v-text-field v-model="body" label="Сообщение" />

    <div class="mt-4 text-right">
      <v-btn type="submit" :loading="loading" color="accent">Отправить</v-btn>
    </div>
  </v-form>
</template>

<script>
import { nanoid } from "nanoid";

export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      token: "",
      title: "",
      body: ""
    };
  },
  methods: {
    handleSubmit() {
      this.$emit("submit", {
        token: this.token,
        data: {
          id: nanoid(),
          name: "message",
          title: this.title,
          body: this.body
        }
      });
    }
  }
};
</script>
