<template>
  <v-form autocomplete="off" @submit.prevent="handleSubmit">
    <v-text-field v-model="token" label="Токен устройства" />

    <v-textarea v-model="data" label="Данные (JSON)" dense auto-grow />

    <div class="mt-4 text-right">
      <v-btn type="submit" :loading="loading" color="accent">Отправить</v-btn>
    </div>
  </v-form>
</template>

<script>
import { nanoid } from "nanoid";

export default {
  props: {
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      token: "",
      data: JSON.stringify({ id: "id", name: "message", title: "Заголовок", body: "Сообщение" }, null, 2)
    };
  },
  methods: {
    handleSubmit() {
      try {
        const data = JSON.parse(this.data);

        if (!data.id) {
          data.id = nanoid();
        }

        this.$emit("submit", { token: this.token, data });
      } catch (e) {
        this.$snackbar.error("Невалидный JSON: <br/>" + e.message);
      }
    }
  }
};
</script>
